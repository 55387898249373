body {
	font-family: Arial, sans-serif;
	margin: 0;
	padding: 20px;
	background-color: #f4f4f4;
}

.jang {
	font-size: 1.7em;
	font-weight: bold;
	margin-top: 20px;
	margin-bottom: 10px;
	text-align: center;
}

.jau__title {
	font-size: 1.2em;
	font-weight: bold;
	cursor: pointer;
	background-color: #d3e4f3;
	padding: 10px;
	border-radius: 5px;
	margin-top: 10px;
}

.jau__title:hover {
	background-color: #b0c7e1;
}

.sectionup {
	margin-top: 20px;
}

.jau__contents {
	background-color: #fff;
	padding: 15px;
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-top: 5px;
}

.content-item {
	background-color: #f9f9f9;
	padding: 10px;
	margin: 5px 0;
	border-radius: 5px;
	border: 1px solid #ddd;
}

.content-subitem {
	padding: 5px 20px;
	margin: 3px 0;
	border-left: 2px solid #ddd;
}

.section-title {
	font-size: 1.2em;
	font-weight: bold;
	margin-bottom: 10px;
}

.date {
	text-align: center;
	margin-top: 20px;
	font-size: 1em;
	color: #333;
}

.signature {
	text-align: center;
	margin-top: 10px;
	font-size: 1.2em;
	font-weight: bold;
	color: #333;
}
