/* 기본 스타일 정의 */
body {
	font-family: Arial, sans-serif;
	margin: 0;
	padding: 20px;
	background-color: #f4f4f4;
}

/* 장 제목 스타일 */
.jang {
	font-size: 1.7em;
	font-weight: bold;
	margin-top: 20px;
	margin-bottom: 10px;
	text-align: center;
}

/* 조항 제목 스타일 */
.jau__title {
	font-size: 1.2em;
	font-weight: bold;
	cursor: pointer;
	background-color: #d3e4f3;
	padding: 10px;
	border-radius: 5px;
	margin-top: 10px;
}

.jau__title:hover {
	background-color: #b0c7e1;
}

/* sectionup 클래스 스타일 */
.sectionup {
	margin-top: 20px;
}

/* 조항 내용 스타일 */
.jau__contents {
	background-color: #fff;
	padding: 15px;
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-top: 5px;
}

/* 조항 목록 스타일 */
.jau__contents ul {
	list-style-type: none; /* 점 제거 */
	padding: 0;
}

/* 리스트 항목 스타일 */
.rule-item {
	margin-bottom: 20px; /* 조항들 간의 간격 조정 */
}

.rule-sub-item {
	margin-bottom: 10px; /* 서브 항목들 간의 간격 조정 */
}

.rule-item::before {
	content: attr(data-index); /* 데이터 속성을 사용하여 인덱스 추가 */
	margin-right: 8px;
}

.rule-item[data-index='0']::before {
	content: '①';
}
.rule-item[data-index='1']::before {
	content: '②';
}
.rule-item[data-index='2']::before {
	content: '③';
}
.rule-item[data-index='3']::before {
	content: '④';
}
.rule-item[data-index='4']::before {
	content: '⑤';
}
.rule-item[data-index='5']::before {
	content: '⑥';
}
.rule-item[data-index='6']::before {
	content: '⑦';
}
.rule-item[data-index='7']::before {
	content: '⑧';
}
.rule-item[data-index='8']::before {
	content: '⑨';
}
.rule-item[data-index='9']::before {
	content: '⑩';
}
.rule-item[data-index='10']::before {
	content: '⑪';
}
.rule-item[data-index='11']::before {
	content: '⑫';
}
.rule-item[data-index='12']::before {
	content: '⑬';
}
.rule-item[data-index='13']::before {
	content: '⑭';
}
.rule-item[data-index='14']::before {
	content: '⑮';
}
.rule-item[data-index='15']::before {
	content: '⑯';
}
.rule-item[data-index='16']::before {
	content: '⑰';
}
.rule-item[data-index='17']::before {
	content: '⑱';
}
.rule-item[data-index='18']::before {
	content: '⑲';
}
.rule-item[data-index='19']::before {
	content: '⑳';
}
.rule-item[data-index='20']::before {
	content: '㉑';
}
.rule-item[data-index='21']::before {
	content: '㉒';
}
.rule-item[data-index='22']::before {
	content: '㉓';
}
.rule-item[data-index='23']::before {
	content: '㉔';
}
.rule-item[data-index='24']::before {
	content: '㉕';
}
.rule-item[data-index='25']::before {
	content: '㉖';
}
.rule-item[data-index='26']::before {
	content: '㉗';
}
.rule-item[data-index='27']::before {
	content: '㉘';
}
.rule-item[data-index='28']::before {
	content: '㉙';
}
.rule-item[data-index='29']::before {
	content: '㉚';
}
.rule-item[data-index='30']::before {
	content: '㉛';
}
.rule-item[data-index='31']::before {
	content: '㉜';
}
.rule-item[data-index='32']::before {
	content: '㉝';
}
.rule-item[data-index='33']::before {
	content: '㉞';
}
.rule-item[data-index='34']::before {
	content: '㉟';
}
.rule-item[data-index='35']::before {
	content: '㊱';
}
.rule-item[data-index='36']::before {
	content: '㊲';
}
.rule-item[data-index='37']::before {
	content: '㊳';
}
.rule-item[data-index='38']::before {
	content: '㊴';
}
.rule-item[data-index='39']::before {
	content: '㊵';
}
.rule-item[data-index='40']::before {
	content: '㊶';
}
.rule-item[data-index='41']::before {
	content: '㊷';
}
.rule-item[data-index='42']::before {
	content: '㊸';
}
.rule-item[data-index='43']::before {
	content: '㊹';
}
.rule-item[data-index='44']::before {
	content: '㊺';
}
.rule-item[data-index='45']::before {
	content: '㊻';
}
.rule-item[data-index='46']::before {
	content: '㊼';
}
.rule-item[data-index='47']::before {
	content: '㊽';
}
.rule-item[data-index='48']::before {
	content: '㊾';
}
.rule-item[data-index='49']::before {
	content: '㊿';
}

/* 필요에 따라 더 많은 인덱스를 추가할 수 있습니다. */

/* 개정 내용 스타일 */
.no-print {
	background-color: #f9f9f9;
	padding: 10px;
	border: 1px solid #eee;
	border-radius: 5px;
	margin-top: 10px;
}

.no-print ul {
	list-style-type: none; /* 점 제거 */
	padding: 0;
}

.no-print li {
	margin-bottom: 5px;
}

/* Roe1GeneralRules.css 파일 내용 중 원문자번호 관련 스타일 예시 */
.roe-number {
	font-size: 12px;
	color: #333;
}
