body {
	font-family: Arial, sans-serif;
	margin: 0;
	padding: 20px;
	background-color: #f4f4f4;
}

.jang {
	font-size: 1.7em;
	font-weight: bold;
	margin-top: 20px;
	margin-bottom: 10px;
	text-align: center;
}

.jau__title {
	font-size: 1.2em;
	font-weight: bold;
	cursor: pointer;
	background-color: #d3e4f3;
	padding: 10px;
	border-radius: 5px;
	margin-top: 10px;
}

.jau__title:hover {
	background-color: #b0c7e1;
}

.sectionup {
	margin-top: 20px;
}

.jau__contents {
	background-color: #fff;
	padding: 15px;
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-top: 5px;
}

.jau__contents ul {
	list-style-type: none;
	padding: 0;
}

.rule-item {
	margin-bottom: 20px;
}

.rule-sub-item {
	margin-bottom: 10px;
}

.rule-item::before {
	content: attr(data-index);
	margin-right: 8px;
}

.rule-item[data-index='0']::before {
	content: '①';
}
.rule-item[data-index='1']::before {
	content: '②';
}
.rule-item[data-index='2']::before {
	content: '③';
}
.rule-item[data-index='3']::before {
	content: '④';
}
.rule-item[data-index='4']::before {
	content: '⑤';
}
.rule-item[data-index='5']::before {
	content: '⑥';
}
.rule-item[data-index='6']::before {
	content: '⑦';
}
.rule-item[data-index='7']::before {
	content: '⑧';
}
.rule-item[data-index='8']::before {
	content: '⑨';
}
.rule-item[data-index='9']::before {
	content: '⑩';
}
.rule-item[data-index='10']::before {
	content: '⑪';
}
.rule-item[data-index='11']::before {
	content: '⑫';
}
.rule-item[data-index='12']::before {
	content: '⑬';
}
.rule-item[data-index='13']::before {
	content: '⑭';
}
.rule-item[data-index='14']::before {
	content: '⑮';
}
.rule-item[data-index='15']::before {
	content: '⑯';
}
.rule-item[data-index='16']::before {
	content: '⑰';
}
.rule-item[data-index='17']::before {
	content: '⑱';
}
.rule-item[data-index='18']::before {
	content: '⑲';
}
.rule-item[data-index='19']::before {
	content: '⑳';
}
.rule-item[data-index='20']::before {
	content: '㉑';
}
.rule-item[data-index='21']::before {
	content: '㉒';
}
.rule-item[data-index='22']::before {
	content: '㉓';
}
.rule-item[data-index='23']::before {
	content: '㉔';
}
.rule-item[data-index='24']::before {
	content: '㉕';
}
.rule-item[data-index='25']::before {
	content: '㉖';
}
.rule-item[data-index='26']::before {
	content: '㉗';
}
.rule-item[data-index='27']::before {
	content: '㉘';
}
.rule-item[data-index='28']::before {
	content: '㉙';
}
.rule-item[data-index='29']::before {
	content: '㉚';
}
.rule-item[data-index='30']::before {
	content: '㉛';
}

.no-print {
	background-color: #f9f9f9;
	padding: 10px;
	border: 1px solid #eee;
	border-radius: 5px;
	margin-top: 10px;
}

.no-print ul {
	list-style-type: none;
	padding: 0;
}

.no-print li {
	margin-bottom: 5px;
}

.roe-number {
	font-size: 12px;
	color: #333;
}

.laborContractStyle {
	margin: 30px;
	font-size: 17px;
}

.section-title {
	font-size: 1rem;
	font-weight: bold;
}
